import {
  createContext,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { auth } from "./firebaseConfig";
import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { AuthState, setAuthInfo } from "../store/slices/authSlice";
import logo from "../assets/logo.png";
import {
  AnonymousQuestionsState,
  clearAnonymousQuestions,
} from "../store/slices/anonymousQuestionsSlice";
import { sizing } from "../styles/sizing";
import { getStatsigClient } from "../utils/statsig";
// import {RootState} from "../store/store";

const AuthContext = createContext<{
  authState: {
    auth: AuthState,
    anonymousQuestions: AnonymousQuestionsState
  },
  setAuthInfo: (userInfo: Partial<AuthState>) => void,
  checkUserOnboardingStatus: () => Promise<boolean>,
} | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const anonymousQuestions = useAppSelector(
    (state: { anonymousQuestions: AnonymousQuestionsState }) =>
      state.anonymousQuestions
  );
  const [showEnterEmailModal, setShowEnterEmailModal] = useState(false);
  const [emailForSignIn, setEmailForSignIn] = useState("");
  const isHandlingAuthRef = useRef(false);

  const handleEmailConfirm = () => {
    if (!validateEmail(emailForSignIn)) {
      alert("Please enter a valid email address."); // You can replace this with a more sophisticated error handling
      return;
    }
    if (emailForSignIn) {
      Cookies.set("emailForSignIn", emailForSignIn, {
        expires: 1,
        secure: true,
        sameSite: "Strict",
      }); // Set cookie for 1 days
      setShowEnterEmailModal(false);
      console.log("calling handleAuthFlow again since email is confirmed");
      handleAuthFlow();

      dispatch(setAuthInfo({ isLoading: true, showEnterEmailModal: false }));
    }
  };

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(String(email).toLowerCase());
  };

  const isAskLinkRoute = () => {
    return /^\/profile\/[^/]+$/.test(window.location.pathname);
  };

  const isAskDetailsRoute = () => {
    return /^\/ask\/[^/]+$/.test(window.location.pathname);
  };

  const updateAuthInfo = (userInfo: Partial<AuthState>) => {
    if (userInfo.userId && userInfo.createdAt) {
      console.log(`Logging user ${userInfo.userId} to statsig`);
      getStatsigClient()
        .updateUserAsync({
          userID: userInfo.userId,
          custom: {
            createdAt: userInfo.createdAt.toString(),
            displayName: userInfo.displayName,
          },
        })
        .then(() => {
          console.log(`Logged user ${userInfo.userId} to statsig`);
        })
        .catch((err) => {
          console.error(
            `Error logging user ${userInfo.userId} to statsig`,
            err
          );
        });
    }
    dispatch(setAuthInfo(userInfo));
  };

  const fetchUserDetails = async () => {
    try {
      const response = await axios.post("/api/getUserDetails");
      console.log("User details retrieved successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Error during user authentication and detail retrieval:",
        error
      );
      throw error;
    }
  };

  const checkUserOnboardingStatus = async (): Promise<boolean> => {
    try {
      const userDetails = await fetchUserDetails();
      if (!userDetails) {
        updateAuthInfo({
          isAuthenticated: false,
          isLoading: false,
        });
        return false;
      }
      updateAuthInfo({
        userId: userDetails.userId,
        isAuthenticated: true,
        displayNameCompleted: !!userDetails.displayName,
        displayName: userDetails.displayName,
        isOnboardingComplete: userDetails.isOnboardingComplete,
        isOnBoardingTutorialComplete: userDetails.isOnBoardingTutorialComplete,
        uniqId: userDetails.uniqId,
        isLoading: false,
        dateOfBirth: userDetails.dateOfBirth,
        profilePic: userDetails.profilePic,
        createdAt: userDetails.createdAt,
        onboardingRewardUserId: userDetails.onboardingRewardUserId,
        numNotesOrQuestionsAnswered:
          userDetails.numNotesOrQuestionsAnswered || 0,
      });
      return true;
    } catch (error) {
      console.error("Error fetching user details:", error);
      return false;
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.post(
        "/api/auth/refresh-token",
        {},
        { withCredentials: true }
      );
      return response.data.sessionToken;
    } catch (error) {
      console.error("Error refreshing token:", error);
      throw error;
    }
  };

  const setAuthToken = (token: string) => {
    axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
    localStorage.setItem("sessionToken", token);
  };

  // Add this near the top of your file, after imports
  const API_BASE_URL =
    process.env.NODE_ENV === "production"
      ? "" // Empty string in production since API and client are served from same origin
      : "http://localhost:3002"; // Development API URL

  // Set default base URL for all axios requests
  useEffect(() => {
    axios.defaults.baseURL = API_BASE_URL;
    axios.defaults.withCredentials = true;

    // Interceptor for requests
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        console.log("Request Interceptor, config:", config);
        const token = localStorage.getItem("sessionToken");
        console.log("Request Interceptor, token:", token);
        if (token && config.headers) {
          config.headers["authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Interceptor for responses
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        console.log("Response Interceptor, error:", error);
        const tokenExpired =
          error?.response?.status === 403 && !originalRequest._retry;
        const jwtEmpty = error?.response?.status === 401;
        const hasRefreshToken = error?.response?.data?.hasRefreshToken;
        const isUrlWhitelisted =
          originalRequest.url === "/api/auth/refresh-token" ||
          originalRequest.url === "/api/auth/authenticate" ||
          originalRequest.url === "/api/auth/logout" ||
          originalRequest.url === "/api/askBio" ||
          originalRequest.url === "/api/sendEmail" ||
          originalRequest.url === "/api/getAskeeUser";
        const tryingToAccessNonExistentProfile =
          error?.response?.status === 404 && isUrlWhitelisted;
        if (
          hasRefreshToken &&
          (tokenExpired || (jwtEmpty && !isUrlWhitelisted))
        ) {
          originalRequest._retry = true;
          try {
            const newToken = await refreshToken();
            setAuthToken(newToken);
            originalRequest.headers["authorization"] = `Bearer ${newToken}`;
            updateAuthInfo({ isAuthenticated: true });
            return axios(originalRequest);
          } catch (refreshError) {
            // Handle refresh token failure (e.g., logout user)
            updateAuthInfo({
              userId: null,
              isAuthenticated: false,
              isLoading: false,
            });
            console.log("redirecting to signup from response interceptor");
            window.history.pushState(null, "", "/signup");
            return Promise.reject(refreshError);
          }
        } else if (tryingToAccessNonExistentProfile) {
          console.log(
            "Meaning user trying to access a profile that doesn't exist, do nothing"
          );
        } else if(error?.response?.status === 401 || error?.response?.status === 403) {
          updateAuthInfo({
            isAuthenticated: false,
            isLoading: false,
            userId: null,
          });
          console.log(`request url: ${originalRequest.url} failed`);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  useEffect(() => {
    let mounted = true;

    const unregisterAuthObserver = auth.onAuthStateChanged(
      async (firebaseUser) => {
        if (
          mounted &&
          !authState.isAuthenticated &&
          !isHandlingAuthRef.current
        ) {
          isHandlingAuthRef.current = true;
          try {
            await handleAuthFlow();
          } finally {
            if (mounted) {
              isHandlingAuthRef.current = false;
            }
          }
        }
      }
    );

    return () => {
      mounted = false;
      unregisterAuthObserver();
    };
  }, []);

  const handleAuthFlow = async () => {
    console.log("handleAuthFlow called", {
      isEmailLink: isSignInWithEmailLink(auth, window.location.href),
      currentPath: window.location.href,
    });

    if (isSignInWithEmailLink(auth, window.location.href)) {
      try {
        await handleEmailSignInLink();
      } catch (error) {
        console.error("Error during email sign-in:", error);
      }
    } else {
      await checkUserOnboardingStatus();
      if (isAskLinkRoute() || isAskDetailsRoute()) {
        window.history.pushState(null, "", window.location.pathname);
      } else {
        window.history.pushState(null, "", "/");
      }
    }
  };

  const handleEmailSignInLink = async () => {
    console.log(
      `isSignInWithEmailLink condition is true, with link being ${window.location.href}`
    );

    // Get encrypted email from URL
    const urlParams = new URLSearchParams(window.location.search);
    const encryptedEmail = urlParams.get("encEmail");
    const onboardingRewardUserId =
      urlParams.get("onboardingRewardUserId") || undefined;

    if (!encryptedEmail) {
      console.log(
        "encrypted email is null, setting showEnterEmailModal to true"
      );
      dispatch(setAuthInfo({ isLoading: false, showEnterEmailModal: true }));
      setShowEnterEmailModal(true);
      return;
    }

    try {
      // Decrypt email from server
      const response = await axios.post("/api/auth/decryptEmail", {
        encryptedEmail,
      });
      const email = response.data.email;

      const result = await signInWithEmailLink(
        auth,
        email,
        window.location.href
      );

      const idToken = await result.user.getIdToken();
      console.log("idToken", idToken);
      await authenticateWithIdToken(idToken, onboardingRewardUserId);
      const isSuccess = await checkUserOnboardingStatus();
      if (isSuccess) {
        getStatsigClient()?.logEvent("confirm_link");
      }
      dispatch(clearAnonymousQuestions());
      window.history.pushState(null, "", "/");
    } catch (error) {
      if (getStatsigClient()) {
        getStatsigClient()?.logEvent(
          "sign_in_failed",
          JSON.stringify(error, null, 2)
        );
      }
      console.error("Error during sign-in:", error);
      const result = await checkUserOnboardingStatus();
      if (result) {
        console.log("is authenticated, pushing to home");
        window.history.pushState(null, "", "/");
      } else {
        console.log(
          "is not authenticated, setting showLinkExpiredModal to true"
        );
        dispatch(
          setAuthInfo({
            isLoading: false,
            showEnterEmailModal: false,
            showLinkExpiredModal: true,
          })
        );
      }
    }
  };

  const authenticateWithIdToken = async (
    idToken: string,
    onboardingRewardUserId?: string
  ) => {
    let anonymousQuestionsIds: string[] | undefined = undefined;
    if (anonymousQuestions.count > 0) {
      console.log(
        "has anonymous questions, so setting the anonymousQuestionsIds to auth"
      );
      anonymousQuestionsIds = anonymousQuestions.askedQuestions.map(
        (question) => question.askId
      );
    }

    const response = await axios.post("/api/auth/authenticate", {
      idToken,
      anonymousQuestionsIds,
      onboardingRewardUserId,
    });
    const { sessionToken } = response.data;
    setAuthToken(sessionToken);

    // Fetch user details and update auth context
    const userDetails = await fetchUserDetails();
    if (!userDetails) {
      updateAuthInfo({
        isAuthenticated: false,
        isLoading: false,
      });
      return;
    }
    console.log("setting auth info");
    updateAuthInfo({
      userId: userDetails.userId,
      isAuthenticated: true,
      isLoading: false,
    });
  };

  if (authState.isLoading) {
    return <div>Loading...</div>; // Or any loading component
  }

  return (
    <AuthContext.Provider
      value={{
        authState: {
          auth: authState,
          anonymousQuestions: anonymousQuestions,
        },
        setAuthInfo: updateAuthInfo,
        checkUserOnboardingStatus,
      }}
    >
      {children}
      <Modal
        isOpen={authState.showLinkExpiredModal ?? false}
        onClose={() => updateAuthInfo({ showLinkExpiredModal: false })}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          bgColor="#1c1c1c"
          color="white"
          borderRadius="xl"
          boxShadow="0 0 20px rgba(255, 0, 80, 0.3)"
          width="100%"
          maxW={sizing.maxAppContentWidthPx}
          mx="auto"
          justifyContent="center"
          _focus={{
            outline: "none",
            boxShadow: "none",
          }}
        >
          <Box
            as="img"
            src={logo}
            alt="dots logo"
            height={{ base: "32px", md: "40px", lg: "48px" }}
            maxWidth="100px"
            mx="auto"
            display="block"
            my={4}
          />

          <ModalHeader
            pt={4}
            textAlign="center"
            fontSize={{ base: "lg", md: "lg", lg: "xl" }}
            fontWeight="bold"
            pb={4}
          >
            Your sign-in link has expired
          </ModalHeader>

          <ModalBody pt={4} pb={4} gap={8} textAlign="center">
            <Text>
              The sign-in link you used is no longer valid. Please click the
              button below to sign in again.
            </Text>
            <Button
              colorScheme="pink"
              size="lg"
              borderRadius="full"
              onClick={() => {
                updateAuthInfo({ showLinkExpiredModal: false });
                window.history.pushState(null, "", "/signup");
              }}
            >
              Sign In Again
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={showEnterEmailModal && !authState.showLinkExpiredModal}
        onClose={() => {}}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          bgColor="#1c1c1c"
          color="white"
          borderRadius="xl"
          maxW={sizing.maxAppContentWidthPx}
          mx="auto"
          justifyContent="center"
        >
          <Box
            as="img"
            src={logo}
            alt="dots logo"
            height={{ base: "32px", md: "40px", lg: "48px" }}
            maxWidth="100px"
            mx="auto"
            display="block"
            my={4}
          />

          <ModalHeader
            pt={4}
            textAlign="center"
            fontSize={{ base: "lg", md: "lg", lg: "xl" }}
            fontWeight="bold"
            pb={4}
          >
            Reconfirm your email to sign-in
          </ModalHeader>

          <ModalBody pt={8} pb={8}>
            <Box display="flex" justifyContent="center">
              <Input
                placeholder="Enter your email"
                value={emailForSignIn}
                onChange={(e) => setEmailForSignIn(e.target.value)}
                bg="white"
                color="black"
                py={7}
                pl={6}
                width="100%"
                maxW={sizing.maxAppContentWidthPx}
                size="lg"
                height={{ base: "65px", md: "78px", lg: "78px" }}
                fontSize={{ base: "14px", md: "16px", lg: "18px" }}
                borderRadius="30px"
                _focus={{
                  outline: "none",
                  boxShadow: "none",
                }}
              />
            </Box>
          </ModalBody>

          <ModalFooter justifyContent="center" pb={8} mt={4}>
            <Button
              backgroundColor="#FF0050"
              color="white"
              size="lg"
              width="100%"
              maxW={sizing.maxAppContentWidthPx}
              height={{ base: "56px", md: "58px", lg: "60px" }}
              borderRadius="30px"
              _hover={{ bg: "#ef004b" }}
              fontSize={{ base: "sm", md: "md", lg: "lg" }}
              onClick={handleEmailConfirm}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AuthContext.Provider>
  );
};
